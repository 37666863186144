import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { differenceInYears } from 'date-fns';
import AddToCalendar from 'react-add-to-calendar';

import HealthAssessmentHeader from '../components/HealthAssessmentHeader';
import CommunicationCard from '../components/CommunicationCard';

import { useHealthAssessmentCtx } from '../contexts';

import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';

import './HealthAssessmentConfirmed.sass';

export default () => {
  const history = useHistory();
  const {
    haCtxSelectors: { haConfirmedPage, isEmptyState },
  } = useHealthAssessmentCtx();
  const isEmptyStateData = isEmptyState();
  const {
    startTime,
    endTime,
    locationName,
    locationLineOne,
    locationLineTwo,
    locationTownCity,
    locationPostcode,
    locationText,
    haTypeTitle,
    haTypeDetails,
    haTypeDuration,
    dateOfBirth,
    sexAssignedAtBirth,
    eligibleForMammogram,
    activityId,
    payorId,
  } = haConfirmedPage();

  // Redirect users to start of booking flow if the state is empty
  useEffect(() => {
    const payorId = sessionStorage.getItem('PayorID');
    const activityId = sessionStorage.getItem('ActivityID');
    const idaasId = sessionStorage.getItem('IDaaSID');
    if (isEmptyStateData) {
      history.push(
        `/healthAssessmentDetails/?PayorID=${payorId}&ActivityID=${activityId}&IDaaSID=${idaasId}`,
      );
    }
  }, []);

  const patientAge = differenceInYears(new Date(), new Date(dateOfBirth));
  
  const appointmentDate = moment(startTime).format('dddd Do MMMM');
  const slotStartTime = moment(startTime).format('HH:mm');

  const addToCalendarTitle = `${haTypeTitle} - ${locationName}`;
  const addToCalendarLocation = `${locationLineOne} ${locationLineTwo} ${locationTownCity} ${locationPostcode}`;

  const journey_type = haTypeTitle == "360 Health Assessment" || 
    haTypeTitle == "360+ Health Assessment" || 
    haTypeTitle == "Female Health Assessment" || 
    haTypeTitle == "Lifestyle Health Assessment" ||
    haTypeTitle == "Lifestyle+ GP Health Assessment" ||
    haTypeTitle == "Lifestyle+ VGP Health Assessment" || 
    haTypeTitle == "NomLifestyle+ Health Assessment" ? "ha1" : "ha2x";
  
  const triumLink =
    process.env.REACT_APP_TRIUM_BASE_URL + journey_type + '?ActivityID=' + activityId + '&PayorID=' + payorId;

  // Encode the address for URL
  const addressMap = encodeURIComponent(locationText);

  return (
    <div className="health-assessment-confirmed-container">
      <HealthAssessmentHeader type="confirmed" name="Your Health Assessment is booked" />

      <h2 className="page-title">{haTypeTitle}</h2>

      <table className="table">
        <tbody>
          <tr>
            <td>
              <p>Date:</p>
            </td>
            <td>
              <p>{appointmentDate}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Time:</p>
            </td>
            <td>
              <p>{slotStartTime} (Please aim to arrive 15 minutes before your appointment)</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Duration:</p>
            </td>
            <td>
              <p>{haTypeDuration}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Location:</p>
            </td>
            <td>
              <p className="address-line-one">{locationName}</p>
              <p>{locationLineOne}</p>
              <p>{locationLineTwo}</p>
              <p>{locationTownCity}</p>
              <p>{locationPostcode}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Directions to site:</p>
            </td>
            <td>
              <a
                className="map-link"
                href={`https://maps.google.com/maps?q=${addressMap}`}
                target="_blank"
                rel="noopener noreferrer" // Security best practice for opening external links
              >
                View location on map
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="link-wrapper">
        {/* Add specific appointment details to AddToCalender component */}
        <AddToCalendar
          event={{
            title: addToCalendarTitle,
            location: addToCalendarLocation,
            description: haTypeDetails,
            startTime: startTime,
            endTime: endTime,
          }}
        />
        <span className="icon__wrapper">
          <ArrowRight />
        </span>
      </div>

      {sexAssignedAtBirth === 'female' && eligibleForMammogram && (
        <CommunicationCard
          // TODO: copy should be handled in the component itself
          type="warning"
          title="Call us to book your mammogram"
          copy="Mammogram appointments are separate to the Health Assessment and need to be booked by phone. If you meet the below criteria, please call us on <a href='tel:03452302040'>03452 302 040</a> to book. Lines are open Monday to Friday, 8am to 8pm.<ul><li>You’re a female over the age of 40</li><li>You’re registered with a GP</li><li>You don't have any current breast symptoms</li><li>You haven't had a mammogram in the last 12 months</li></ul>"
        />
      )}

      <hr className="divider" />

      <h3 className="h3">How to prepare for your Health Assessment</h3>
      {
        (patientAge > 44 && (
          (sexAssignedAtBirth === 'male' && (haTypeTitle === "360 Health Assessment" || haTypeTitle === "360+ Health Assessment")) ||
          (sexAssignedAtBirth === 'female' && (haTypeTitle === "360 Health Assessment" || haTypeTitle === "360+ Health Assessment"))
        )) && (
          <CommunicationCard
            type="warning"
            title="Using your FIT kit before your Health Assessment"
            copy="Before your assessment, you will receive a FIT kit in the post containing a bowel cancer screening tool. The kit will also contain detailed use instructions. To make sure your screening results are available on the day of your assessment, please return your test to us at least <strong>five working days</strong> before your appointment using the prepaid envelope provided.
            <br />
            If results aren't available during your assessment, we'll either:
            <br />
            <ol>
            <li>Include them in your Health Assessment report</li>
            <li>Send them via letter</li>
            <li>Have a Health Assessment Doctor communicate them to you</li>
            </ol>
            <br />
            If you don't receive your FIT kit 8-10 days before your appointment, please contact us on 03452 302 040. Lines are open Monday to Friday, 8am to 8pm."
          />
        )
      }

      <p className="text">
        Choose your modules (if included) at least three working days before your appointment and
        make sure you complete your online health risk assessment on Trium® at least 72 hours
        beforehand. The day before your Health Assessment, drink plenty of water, rest and avoid
        smoking. If you’re having a blood test, we recommend fasting for 8 to 10 hours beforehand
        for best results.{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`${process.env.REACT_APP_CMS_PUBLIC_URL}health-assessments-hub/preparing-for-your-health-assessment-appointment`}
        >
          Find out more about how to prepare for your Health Assessment
        </a>
        .
      </p>
      <div className="ctas__wrapper mt-40">
        <a
          className="primary-btn m--complete_your_pre-assessment"
          target="_blank"
          rel="noreferrer noopener"
          href={triumLink}
        >
          Complete your pre-assessment
        </a>
      </div>

      <CommunicationCard
        // TODO: copy should be handled in the component itself
        type="information"
        copy='If you require a language translator or assistance due to a visual or mobility impairment, please call us on <a href="tel:03452302040">03452 302 040</a> before your appointment. Lines are open Monday to Friday, 8am to 8pm.
        <br><br>
        If you are hearing impaired and are unable to reach us by phone, the "Relay UK" or "Big Word" language services providers can facilitate you getting in touch.'
      />
      
      <hr className="divider" />

      <h3 className="h3">If you can&apos;t make your appointment</h3>
      <p className="cancellation-text">
        To cancel your appointment, click ’Manage your booking below’ or to reschedule your
        appointment, call us on <a href="tel:03452302040">03452 302 040</a> (Monday to Friday, 8am
        to 8pm). Please be aware that a 50% charge applies for cancellations or changes made with
        less than three full working days’ notice. You’ll be charged the full fee if you fail to
        attend or arrive over 15 minutes late. For more information, read our{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`${process.env.REACT_APP_CMS_PUBLIC_URL}health-assessments-hub/our-health-assessments-faqs`}
        >
          FAQs
        </a>
        .
      </p>

      <div className="link-wrapper">
        {/* Add location URL - to My Bookings screen on NH.com */}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={process.env.REACT_APP_MEMBER_ACCOUNT_BASE_URL}
        >
          Manage your booking
          <ArrowRight />
        </a>
      </div>
    </div>
  );
};
