import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useForm from '../hooks/useForm';
import FormField from '../components/FormField';
import CTAButton from '../components/CTAButton';
import HealthAssessmentHeader from '../components/HealthAssessmentHeader';
import { DETAILS_FORM } from '../utils/forms';

import { useHealthAssessmentCtx } from '../contexts';

import './HealthAssessmentDetails.sass';

export default () => {
  const history = useHistory();
  const detailsForm = useForm(DETAILS_FORM);
  const formComplete = Object.values(detailsForm).reduce(
    (valid, field) => valid && (field.touched ? field.valid : field.isValid()),
    true,
  );
  const {
    haCtxSelectors: { appointments },
    haCtxActions,
  } = useHealthAssessmentCtx();
  const appointmentData = appointments();

  // Get PayorID, ActivityID, IDaasID from query params
  const urlParams = new URLSearchParams(window.location.search);
  const payorId = urlParams.get('PayorID');
  const activityId = urlParams.get('ActivityID');
  const idaasId = urlParams.get('IDaaSID');
  sessionStorage.setItem('PayorID', payorId);
  sessionStorage.setItem('ActivityID', activityId);
  sessionStorage.setItem('IDaaSID', idaasId);

  useEffect(() => {
    const getAppointments = async () => await haCtxActions.getAppointments(idaasId, 'HA');
    getAppointments();
  }, []);

  // Store data, call API - GET Eligibility & direct user to next page
  const updateDetailsFormAndCallAPI = useCallback(() => {
    // Store user details to state
    haCtxActions.addDetails({
      dateOfBirth: moment({
        ...detailsForm.date_of_birth.value,
        month: detailsForm.date_of_birth.value.month - 1, // Months are zero-indexed
      }).format('YYYY-MM-DD'),
      sexAssignedAtBirth: detailsForm.sex_assigned_at_birth.value,
      gender: detailsForm.gender.value,
      idaasId,
      payorId,
      activityId,
    });
    
    // Call GET Eligibility api
    const parameters = {
      idaasId: idaasId,
      payor: payorId,
    };
    const getEligibility = async () => await haCtxActions.getEligibility(parameters);
    getEligibility();
    let containsFutureBooking = appointmentData?.some(obj => {
      const startDate = new Date(obj['appointment-start-datetime']);
      const status = obj['appointment-status'];
      const now = new Date();
      return startDate > now && status !== 'cancelled';
    });
    if (containsFutureBooking) {
      // Direct user to booking page
      history.push(`/health-assessments/appointments/?idaas-id=${idaasId}`);
    } else {
      // Direct user to next page in booking journey
      history.push('/healthAssessmentTypes/');
    }
  }, [detailsForm, idaasId, payorId]);

  return (
    <div className="health-assessment-details-container">
      <HealthAssessmentHeader />

      <h2 className="page-title">Enter your details</h2>

      <div className="communication-wrapper">
        <p>
          Please let us know the following personal details. This will allow us to tailor the Health
          Assessment to you, so you can get the most out of it.
        </p>
        <p>*Mandatory fields</p>
      </div>

      <div className="form-field">
        <FormField field={detailsForm.date_of_birth} errorEmpty="Please enter your date of birth" />
        <div className="sex-at-birth-field-wrapper">
          <FormField
            field={detailsForm.sex_assigned_at_birth}
            errorEmpty="Please select your sex assigned at birth"
          />
        </div>
        <div className="gender-field-wrapper">
          <FormField field={detailsForm.gender} errorEmpty="Please select your gender" />
        </div>
      </div>

      <div className="ctas-wrapper">
        <CTAButton
          action={{
            call: formComplete ? () => updateDetailsFormAndCallAPI : null,
            type: 'CONTINUE',
            name: 'Continue',
          }}
          isNotAsync
          onAction={updateDetailsFormAndCallAPI}
        />
      </div>
    </div>
  );
};
