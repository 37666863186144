import React from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { ReactComponent as CalendarUnavailable } from '../static/img/calendar-unavailable.svg';

import './UnavailableCard.sass';

export default ({ type, title }) => {
  const history = useHistory();

  function goBack() {
    history.goBack();
  }
  return (
    <div className="unavailable-card">
      <CalendarUnavailable />
      <div className="responsive-container">
        <h4 className="title">{title}</h4>
        <p className="text">
          {type === 'female' && (
            <>
              Unfortunately we don&apos;t have any female practitioners available at this location.
              Please{' '}
              <Link to="#" onClick={goBack}>
                try another location
              </Link>{' '}
              or adjust your preferences.
            </>
          )}
          {type === 'male' && (
            <>
              Unfortunately we don&apos;t have any male practitioners available at this location.
              Please{' '}
              <Link to="#" onClick={goBack}>
                try another location
              </Link>{' '}
              or adjust your preferences.
            </>
          )}
          {type === 'appointment' && (
            <>
              Unfortunately we don&apos;t have any appointments available online at the moment.
              Please{' '}
              <Link to="#" onClick={goBack}>
                try another location
              </Link>{' '}
              or call us on <a href="tel:03452302040">0345 2302 040</a> to discuss your booking.
            </>
          )}
        </p>
      </div>
    </div>
  );
};
