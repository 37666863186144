import { HaActionTypes } from '../hooks/useHealthAssessment.js/useHaActions';

// TODO HA24-386 Move functions to Utils

function moduleCountString(productSection) {
  let doctorModules = '',
    physiologistModules = '',
    doctorCount = 0,
    physiologistCount = 0,
    modulesString = null,
    modulesNumber = 0;
  for (let section of productSection) {
    if (section['section-type'].toLowerCase() === 'doctor') {
      doctorModules = `${section.quantity} doctor`;
      doctorCount = section.quantity;
    }
    if (section['section-type'].toLowerCase() === 'physiologist') {
      physiologistModules = `${section.quantity} physiologist`;
      physiologistCount = section.quantity;
    }
    modulesNumber = modulesNumber + doctorCount + physiologistCount;
  }
  if (modulesNumber > 0) {
    modulesString = `${doctorModules} ${physiologistModules} module${modulesNumber !== 1 ? 's' : ''}`;
  }
  return modulesString;
}

function formatTitle(title) {
  const typeTitleLowerCase = title.toLowerCase();
  return typeTitleLowerCase.replace(/\s+/g, '_');
}

export const haReducer = (state, action) => {
  switch (action.type) {
    case HaActionTypes.LoadingTrue:
      return {
        ...state,
        loading: true,
      };
    case HaActionTypes.AddLocationName:
      return {
        ...state,
        locationName: action.data,
      };
    case HaActionTypes.ClearSearch:
      return {
        ...state,
        locationName: '',
        locationsOrderedByDistance: [],
      };
    case HaActionTypes.AddDetails:
      return {
        ...state,
        loading: false,
        details: action.detailsForm,
      };
    case HaActionTypes.GetEligibility: {
      // Filter and only return 10 HAs - Life 2-4, Pro 1-4, Online, Online + and Pro Online
      let haRange = [
        'Life 2',
        'Life 3',
        'Life 4',
        'Pro 1',
        'Pro 2',
        'Pro 3',
        'Pro 4',
        'Life Online',
        'Life Online + Bloods',
        'Pro Online',
        '360',
        '360+',
        'Female',
        'Lifestyle',
        'Lifestyle+ GP',
        'Lifestyle+ VGP',
        'NomLifestyle+'
      ];
      const filteredData = action.data?.['eligible-for']
        .filter(HAType => haRange.some(str => HAType.name.includes(str)));
      const orderedAndFilteredData = filteredData.sort(
        (a, b) => b?.['package-sequence'] - a?.['package-sequence'],
      );

      const eligibilityData = orderedAndFilteredData.map(ha => ({
        ...ha,
        ['appointment-type']: ha['appointment-type'],
        ['total-duration']: ha['total-duration'] ? ha['total-duration'] + ' minutes' : null,
        name: ha.name,
        modules: moduleCountString(ha['product-components'].sections),
        ['bookable-online']: ha['bookable-online'],
        formattedName: formatTitle(ha.name),
      }));
      const mammogram =
        action.data['eligible-for']?.some(HAType => {
          return HAType['name'] === 'Mammogram';
        }) || false;
      const pro4 =
        action.data['eligible-for']?.some(HAType => {
          return HAType.name.includes('Pro 4');
        }) || false;

      return {
        ...state,
        loading: false,
        eligibility: {
          ...action.data,
          ['eligible-for']: eligibilityData,
          eligibleForMammogram: mammogram,
          pro4Eligible: pro4,
        },
      };
    }
    case HaActionTypes.AddType:
      return {
        ...state,
        loading: false,
        haType: action.haType,
      };
    case HaActionTypes.GetLocations:
      return {
        ...state,
        loading: false,
        locations: action.data,
      };
    case HaActionTypes.GetAppointments:
      return {
        ...state,
        loading: false,
        appointments: action.data,
      };
    case HaActionTypes.AddLocationsWithCoords:
      return {
        ...state,
        loading: false,
        locationsWithCoords: action.locationsWithCoords,
      };
    case HaActionTypes.AddOrderedLocationsWithDistance: {
      const corporateRange = [
        'Allen & Overy',
        'AMEX - John Street',
        'AMEX - Sussex House (Brighton)',
        'Astra Zeneca Macclesfield',
        'Bank of England',
        'BBH',
        'BNY Mellon - Queen Victoria Street',
        'Discovery',
        'JP Morgan Bournmouth',
        'JP Morgan  BANK',
        'JP Morgan VICTORIA',
        'Micosoft KS',
        'Microsoft TVP',
        'Nomura',
        'Schroders',
        'Barts',
      ];
      const nonHaLocationIds = [
        'L3273',
        'L873',
        'L883',
        'L895',
        'L1151',
        'L1155',
        'L1161',
        'L1221',
        'L1233',
        'L1237',
        'L1251',
        'L1253',
        'L1261',
        'L1263',
        'L1269',
        'L1705',
        'L3054',
      ];
      const corporateLocations = action.locationsWithDistance?.filter(location =>
        corporateRange.includes(location.name),
      );
      const otherLocations = action.locationsWithDistance?.filter(
        location => !corporateRange.includes(location.name),
      );
      const orderedCorporateLocations = corporateLocations?.sort(
        (a, b) => a?.distance - b?.distance,
      );
      const orderedOtherLocations = otherLocations?.sort((a, b) => a?.distance - b?.distance);
      const locationsOrderedByDistance = orderedCorporateLocations
        ?.concat(orderedOtherLocations)
        ?.filter(location => !nonHaLocationIds.includes(location.identifier.value));
      return {
        ...state,
        loading: false,
        locationsOrderedByDistance: locationsOrderedByDistance,
      };
    }
    case HaActionTypes.AddLocation:
      return {
        ...state,
        loading: false,
        selectedLocation: action.location,
      };

    case HaActionTypes.GetSlots:
      return {
        ...state,
        loading: false,
        slots: action.data,
      };
    case HaActionTypes.AddSlot:
      return {
        ...state,
        loading: false,
        selectedSlot: action.slot,
      };
    case HaActionTypes.PostAppointment:
      return {
        ...state,
        loading: false,
        appointmentProposed: action.data,
      };
    case HaActionTypes.AddConfirm:
      return {
        ...state,
        loading: false,
        confirm: action.confirm,
      };
    case HaActionTypes.PutAppointment:
      return {
        ...state,
        loading: false,
        appointmentBooked: action.data,
      };
    case HaActionTypes.CancelAppointment:
      return {
        ...state,
        loading: false,
        appointmentCancelled: action.data,
      };
    case HaActionTypes.GetPatient:
      return {
        ...state,
        loading: false,
        patient: action.data,
      };
    case HaActionTypes.PutPatient:
      return {
        ...state,
        loading: false,
        patientUpdated: action.data,
      };
    case HaActionTypes.Error:
      return {
        ...state,
        loading: false,
        error: action.err.message,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
