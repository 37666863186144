import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import HealthAssessmentHeader from '../components/HealthAssessmentHeader';
import UnavailableCard from '../components/UnavailableCard';
import CommunicationCard from '../components/CommunicationCard';
import CTAButton from '../components/CTAButton';
import FormField from '../components/FormField';
import BackBtn from '../components/BackBtn';
import FlatList from '../components/FlatList';
import DaysToggle from '../components/DaysToggle';
import TimeslotToggle from '../components/TimeslotToggle';
import { useHaBookings } from '../hooks/useHealthAssessment.js';

import { ReactComponent as Location } from '../static/img/location.svg';
import { ReactComponent as PersonPlus } from '../static/img/person-plus.svg';

import { SLOTS_FORM } from '../utils/forms';
import useForm from '../hooks/useForm';

import { useHealthAssessmentCtx } from '../contexts';

import './HealthAssessmentSlots.sass';

export default () => {
  const history = useHistory();
  const slotsForm = useForm(SLOTS_FORM);

  const {
    haCtxSelectors: { haSlotsPage, isEmptyState },
    haCtxActions,
  } = useHealthAssessmentCtx();
  const isEmptyStateData = isEmptyState();
  const {
    loading,
    slotsData,
    appointmentType,
    appointmentTypeFormatted,
    activityId,
    patient,
    locations,
    payorId,
    haTypeTitle,
    haLocationName,
    sexAssignedAtBirth,
  } = haSlotsPage();
  // this is the data from the api response
  const { days, timeslots, selectedSlot } = useHaBookings(slotsData);

  // Redirect users to start of booking flow if the state is empty
  useEffect(() => {
    const payorId = sessionStorage.getItem('PayorID');
    const activityId = sessionStorage.getItem('ActivityID');
    const idaasId = sessionStorage.getItem('IDaaSID');
    if (isEmptyStateData) {
      history.push(
        `/healthAssessmentDetails/?PayorID=${payorId}&ActivityID=${activityId}&IDaaSID=${idaasId}`,
      );
    }
  }, []);

  // Call API - GET Slots
  useEffect(() => {
    if (!loading) {
      const parameters = {
        appointment_type: appointmentTypeFormatted,
        upgrade_to: appointmentTypeFormatted,
        patient: patient,
        locations: locations,
        date_from: moment(selectedSlot[0]?.start).format('YYYY-MM-DD'),
        show: 'best',
        practitioner_sex: slotsForm?.practitioner_gender.value,
        payor: payorId,
      };
      const getSlots = async () => {
        await haCtxActions.getSlots(parameters);
      };
      getSlots();
    }
  }, [slotsForm.practitioner_gender.value]);

  const addSlotAndCallAPI = useCallback(
    (id, start, end) => {
      // Store data to state
      const slotData = {
        id: id,
        startTime: start,
        endTime: end,
        appointmentStatus: 'Proposed',
        practitionerGenderPreference: slotsForm.practitioner_gender.value,
      };
      haCtxActions.addSlot(slotData);
      const body = {
        resourceType: 'Appointment',
        extension: [
          {
            url: 'http://fhir.lumeon.com/dstu2/appointment/payor',
            valueReference: {
              reference: `Organization/C${payorId}`,
            },
          },
          {
            url: 'http://fhir.lumeon.com/dstu2/appointment/type',
            valueReference: {
              reference: `HealthcareService/${appointmentType}`,
            },
          },
          {
            url: 'http://fhir.lumeon.com/dstu2/appointment/care-plan',
            valueReference: {
              reference: `CarePlan/${activityId}`,
            },
          },
          {
            url: 'http://fhir.lumeon.com/dstu2/appointment/communication-schemes',
            valueBoolean: true,
          },
        ],
        status: 'proposed',
        start: start,
        end: end,
        slot: {
          reference: `Slot/${id}`,
        },
        type: {
          coding: [
            {
              code: appointmentTypeFormatted,
            },
          ],
        },
        participant: [
          {
            required: 'required',
            status: 'accepted',
            actor: {
              reference: `Patient/${patient}`,
            },
          },
        ],
      };
      // Call POST Appointment API
      const postAppointment = async () => await haCtxActions.postAppointment(body);
      postAppointment();
      // Direct user to next page
      history.push('/healthAssessmentConfirm/');
    },
    [slotsForm.practitioner_gender.value],
  );

  const practitionerGenderPreference = useMemo(() => {
    return slotsForm.practitioner_gender.value !== 'unknown'
      ? slotsForm.practitioner_gender.value
      : 'No Preference';
  }, [slotsForm.practitioner_gender.value]);

  return (
    <div className="health-assessment-slots-container">
      <HealthAssessmentHeader />
      <BackBtn name="Back to: Choose a location" />
      <h2 className="page-title">Choose appointment</h2>
      <p className="intro-text">
        Please let us know whether you’d prefer a male or female practitioner, choose the date and
        time of your appointment, and then click ’Select’.
      </p>
      <div className="location-container">
        <h3 className="title">Location</h3>
        <div className="location-sub-container">
          <p className="location-name">{haLocationName}</p>
          <CTAButton
            className="secondary-btn"
            action={{
              name: 'Change location',
              type: 'CHANGE_LOCATION',
              call: async () => history.goBack(),
            }}
          />
        </div>
      </div>
      <hr className="divider" />
      <div className="practitioner-gender-field-wrapper">
        <FormField field={slotsForm.practitioner_gender} />
      </div>
      <CommunicationCard
        // TODO: copy should be handled in the component itself
        type="information"
        copy="We‘ll try our best to accommodate your request based on staff and location availability. Keep in mind that arrangements may change, so we can't guarantee this. Unfortunately, we don‘t accommodate gender requests for Physiologists, only Doctors."
      />
      {slotsData?.entry?.length ? (
        <div className="please-note">
          <p>Please note:</p>
          <ul>
            <li>
              We recommend you fast for 8 to 10 hours before your assessment for best results in
              your blood tests. If you&apos;re diabetic, please eat and drink as normal.
            </li>
            {sexAssignedAtBirth === 'female' && (
              <li>
                If you&apos;re aged 25 to 60, there&apos;ll be a smear test included in your Health
                Assessment. For this we ask you to be mid cycle, which is roughly two weeks
                before/after your period (if you have regular periods). If you do start your period
                just before your Health Assessment, please let us know.
              </li>
            )}
            {sexAssignedAtBirth === 'male' && haTypeTitle.includes('Pro ') && (
              <li>
                If you&apos;re interested in either the &apos;Bone health&apos; or &apos;Sexual
                function&apos; modules, please book an appointment before 11am.{' '}
                <a
                  href="https://www.nuffieldhealth.com/personalised-corporate-health-assessments/understanding-your-modules"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Find out more about the modules
                </a>
                .
              </li>
            )}
            {sexAssignedAtBirth === 'female' && haTypeTitle.includes('Pro ') && (
              <li>
                If you&apos;re interested in the &apos;Bone health&apos; module, please book an
                appointment before 11am.{' '}
                <a
                  href="https://www.nuffieldhealth.com/personalised-corporate-health-assessments/understanding-your-modules"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Find out more about the modules
                </a>
                .
              </li>
            )}
          </ul>
        </div>
      ) : (
        ''
      )}
      {/* Show loading message whilst loading */}
      {loading && <p>loading...</p>}
      {/* Only show timeSlot component when loading is complete & there is data iin the api response */}
      {slotsData?.entry?.length && !loading ? (
        <div className="calendar">
          <DaysToggle {...days} showMonth />
          <TimeslotToggle timeslots={timeslots} />
          <FlatList
            data={selectedSlot}
            renderItem={slot => {
              const { id, end, start } = slot;
              const slotDay = moment(start).format('dddd Do MMMM');
              const slotStartTime = moment(start).format('HH:mm');
              const slotEndTime = moment(end).format('HH:mm');
              return (
                <div className="card" id={id}>
                  <p className="time">
                    {slotStartTime}
                    {' - '}
                    {slotEndTime}
                    <span className="slot-date">{slotDay}</span>
                  </p>
                  <div className="details">
                    <div className="product-title bold">{haTypeTitle}</div>
                    <div className="detail location-wrapper">
                      <span className="location-icon-wrapper">
                        <Location />
                      </span>
                      <div>{haLocationName}</div>
                    </div>
                    <p className="detail">
                      <span className="person-icon-wrapper">
                        <PersonPlus />
                      </span>
                      Gender of practitioner:&nbsp;
                      <span className="practitioner-gender">{practitionerGenderPreference}</span>
                    </p>
                  </div>
                  <div className="cta__wrapper">
                    <CTAButton
                      action={{
                        name: 'Select',
                        type: 'SELECT',
                        call: () => addSlotAndCallAPI(id, start, end),
                      }}
                      isNotAsync
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      ) : (
        ''
      )}
      {/* If no data in api response & no gender preference selected then show the generic unavailable card */}
      {!slotsData?.entry.length &&
        !loading &&
        slotsForm?.practitioner_gender.value === 'unknown' && (
          <UnavailableCard type="appointment" title="No appointments available" />
        )}
      {/* If no data in api response & female gender preference selected then show the female unavailable card */}
      {!slotsData?.entry.length &&
        !loading &&
        slotsForm?.practitioner_gender.value === 'female' && (
          <UnavailableCard type="female" title="No female practitioners available" />
        )}
      {/* If no data in api response & no gender preference selected then show the male unavailable card */}
      {!slotsData?.entry.length &&
        !loading && 
        slotsForm?.practitioner_gender.value === 'male' && (
        <UnavailableCard type="male" title="No male practitioners available" />
      )}
    </div>
  );
};
